@media (min-width: 420px) {
  .footertext {
    @apply text-nowrap;
  }
  .info-btn-icon {
    @apply !w-[60px];
  }
  .info-res {
    @apply p-[20px];
  }
}

@media (min-width: 540px) {
  .responsive-flexbox {
    @apply flex flex-row justify-between;
  }
  .info-btn-wrapper {
    @apply gap-6;
  }
}
@media (min-width: 650px) {
  .responsive-box {
    @apply w-[80%]  mx-auto;
  }
}

@media (min-width: 750px) {
  .responsive-footer {
    @apply flex flex-row justify-between w-[80%] mx-auto;
  }
}
@media (min-width: 850px) {
  .responsive-footerbottombar {
    @apply flex flex-row justify-between w-[80%] mx-auto;
  }
}

.button-hover-arrow::after {
  content: "\279E"; /* Unicode for the right arrow (U+279E) */
  display: inline-block;
  margin-left: 0.5rem;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.button-hover-arrow:hover::after {
  opacity: 1;
}

/* info */

.info-buttons {
  @apply p-[10px] rounded-xl flex flex-col items-center gap-1 cursor-pointer hover:bg-slate-200;
}

.info-btn-icon {
  @apply w-[40px];
}
